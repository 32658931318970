import { PaymentCode } from '@innerwell/dtos';

import { type PlanPricing } from '@/hooks/usePlanPricing';

export const ADS_PLAY_GONG_AFTER_SECONDS = 60 * 10; // 10 minutes
export const ADS_SESSION_DURATION_SECONDS = 60 * 180; // 180 minutes
export const AUDIO_PLAYER_CUT_SONG_ENDING_SECONDS = 4;
export const ADS_AVAILABLE_AFTER_CHECKIN_DAYS = 5; // How many days need to pass since checkin before ADS is available
export const ADS_AVAILABLE_AFTER_ADS_DAYS = 3;
export const MEDICAL_ADS_MIN_WINDOW_HOURS = 24; // TODO: move this to global consts
export const MEDICAL_ADS_TOTAL_SESSIONS = 26;
export const NEW_KAP_ADS_TOTAL_SESSIONS = 6;
export const MEDICAL_8_ADS_TOTAL_SESSIONS = 8;
export const USER_CAN_JOIN_MEET_BEFORE_MINUTES = 15;
export const REMOVE_APPOINTMENT_AFTER_END_MINUTES = 10;
export const UNLIMITED_ADS_SESSIONS = 9999;
export const SQUARE_CHECKOUT_LINK_INTAKE =
   'https://checkout.square.site/buy/6ZFNHMB5YHELOTD32JEL3AKV'; // if the has NOT 'Meet Your Therapist' in the program
export const SQUARE_CHECKOUT_LINK_ADDON_SESSIONS =
   'https://checkout.square.site/buy/FTE3STPOYLUTZWPYVPQ4RSMQ'; // if the user has 'Meet Your Therapist' in the program
export const ZOOM_APP_DOWNLOAD_LINK_IOS =
   'https://apps.apple.com/us/app/zoom-one-platform-to-connect/id546505307';
export const ZOOM_APP_DOWNLOAD_LINK_ANDROID =
   'https://play.google.com/store/apps/details?id=us.zoom.videomeetings&hl=en&gl=US';
export const INNERWELL_LANDING_WEB_URL = 'https://helloinnerwell.com';
export const PRIVACY_POLICY_LINK = `${INNERWELL_LANDING_WEB_URL}/privacy-policy`;
export const TERMS_OF_USE_LINK = `${INNERWELL_LANDING_WEB_URL}/terms-and-conditions`;
export const TELEHEALTH_INFORMED_CONSENT_LINK = `${INNERWELL_LANDING_WEB_URL}/telehealth-informed-consent`;
export const SCHEDULING_LOCATION_AVAILABILTY_MAX_DAYS = 31;
export const INNERWELL_SUPPORT_EMAIL = 'care@helloinnerwell.com';
export const INNERWELL_SUPPORT_PHONE = '(909) 679-3482';
export const INSURANCE_PRODUCT_VARIANTS: PlanPricing[] = [
   {
      id: 3,
      sku: 'clinician',
      treatmentPrice: 0,
      description: 'a',
      slug: 'a',
      therapyPlan: null,
      encountersCount: {},
      allowedPaymentMethods: [PaymentCode.OnceInFull],
      upsellProductSkus: [],
      availableInStates: undefined,
      name: 'Psychiatry Consult',
      typeId: '1',
      title: 'I’d like to start with a Psychiatry Consult',
      subtitle: `<p>During this appointment you and your clinician will decide together if medications including but not limited to ketamine are right for you.</p>`,
      position: 3,
      price: 0,
      isMagentoProduct: false,
      specialPrice: 0,
      discount: 0,
      discountCategories: [],
      positionsByCategory: {},
      numberOfPayments: 1,
      installmentPaymentCode: PaymentCode.OnceInFull,
      installmentPrice: 0,
      installmentSpecialPrice: 0,
   },
   {
      id: 4,
      sku: 'psychotherapist',
      treatmentPrice: 0,
      description: 'a',
      slug: 'a',
      therapyPlan: null,
      encountersCount: {},
      allowedPaymentMethods: [PaymentCode.OnceInFull],
      upsellProductSkus: [],
      availableInStates: undefined,
      name: 'Therapy Consult',
      typeId: '1',
      title: 'I’d like to start with Therapy Consult.',
      subtitle: `<p>Our psychotherapists offer traditional therapies including CBT, ACT, and EMDR, as well as newer, evidence-based approaches such as psychedelic-assisted psychotherapy.</p>
         <br />
         <p>Our psychotherapists cannot determine eligibility for one of our ketamine treatment programs.</p>`,
      position: 3,
      price: 0,
      isMagentoProduct: false,
      specialPrice: 0,
      discount: 0,
      discountCategories: [],
      positionsByCategory: {},
      numberOfPayments: 1,
      installmentPaymentCode: PaymentCode.OnceInFull,
      installmentPrice: 0,
      installmentSpecialPrice: 0,
   },
];

/**
 * Whether address will be validated with Google API on purchase flows or not.
 */
export const NO_ADDRESS_VALIDATION = true;

/**
 * Skipping therapy upsell on initial purchase
 */
export const NO_THERAPY_UPSELL = false;

/** Jotform form ids */
export const JOTFORM_FORM_IDS = {
   TERMS_OF_SERVICE_AND_TELEHEALTH: '243574031673355',
   UPLOAD_ID: '230804456144048',
};
