import { type QueryClient, useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const invalidateFailedPayments = (queryClient: QueryClient) => {
   queryClient.invalidateQueries({ queryKey: queryKeys.failedPayments });
};

export const useFailedPayments = () => {
   return useQuery({
      queryKey: queryKeys.failedPayments,
      queryFn: async () => {
         const response = await webApiClient.payments.getFailedPayments();

         return response.body;
      },
   });
};
