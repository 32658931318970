import { WelkinEncounterTemplateNames } from '@innerwell/dtos';
import { type QueryClient, useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

const HIDE_FROM_BANK = [
   WelkinEncounterTemplateNames.MeetYourTherapistALaCarte,
   WelkinEncounterTemplateNames.EmdrSession,
   WelkinEncounterTemplateNames.EmdrIntake,
   WelkinEncounterTemplateNames.EmdrIntakeInsurance,
];

export const invalidateAppointmentBank = (queryClient: QueryClient) => {
   queryClient.invalidateQueries({ queryKey: queryKeys.appointmentBank });
};

export const useProgramEncounters = () => {
   const { data, ...rest } = useQuery({
      queryKey: queryKeys.programEncounters(),
      queryFn: async () => {
         const response = await webApiClient.scheduling.getAppointmentsFromBank(
            {},
         );

         return response.body.map((encounter) => ({
            ...encounter,
            hideFromBankTab: HIDE_FROM_BANK.includes(encounter.templateName),
         }));
      },
   });

   return {
      ...rest,
      programEncounters: data ?? [],
   };
};
