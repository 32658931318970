import {
   type CheckoutClickPurchaseButtonEventData,
   CustomerIOEvents,
   type InnerwellProductVariant,
   PaymentOption,
} from '@innerwell/dtos';
import TagManager from 'react-gtm-module';

import { type CartTotal, useCart } from '@/contexts/cart-context';
import { useCallback } from 'react';
import { useSession } from '@/contexts/session-context';
import { useInitialScreeningAnswers } from '@/hooks/useInitialScreeningAnswers';
import { useCheckoutStore } from '@/store/checkout-store';
import { handleSentryException } from './sentry';
import { webApiClient } from '@/api-client/apiClient';

type GtmOpts = {
   metadata?: {
      paymentOption?: boolean;
   };
};

export type SimpleInnerwellProductVariant = Pick<
   InnerwellProductVariant,
   'id' | 'sku' | 'name' | 'price' | 'specialPrice' | 'categoryIds'
>;

export const getGtmEcommerceItem = (
   plan: SimpleInnerwellProductVariant,
   paymentOption: PaymentOption | undefined,
   opts: GtmOpts = {
      metadata: {
         paymentOption: false,
      },
   },
) => {
   if (typeof plan.price !== 'undefined') {
      return {
         item_id: plan.sku,
         item_name: plan.name,
         currency: 'USD',
         discount: plan.specialPrice ? plan.price - plan.specialPrice : 0,
         index: plan.id,
         item_category: 'Medicine Plan',
         price: plan.price,
         quantity: 1,
         ...(opts.metadata?.paymentOption ? { paymentOption } : {}),
      };
   }
};

export enum GtmTrackEventName {
   ViewItem = 'view_item',
   AddToCart = 'add_to_cart',
   RemoveFromCart = 'remove_from_cart',
   BeginCheckout = 'begin_checkout',
   AddShippingInfo = 'add_shipping_info',
   Purchase = 'purchase',
}

export interface GtmTrackCartEvent {
   eventName: GtmTrackEventName;
   plans: SimpleInnerwellProductVariant[];
   paymentOption: PaymentOption | undefined;
   additionalData?: Record<string, string>;
   cartTotals?: CartTotal;
   userData?: {
      email?: string;
      phone?: string;
      first_name?: string;
      last_name?: string;
      state?: string;
      city?: string;
      zip?: string;
      patient_id?: string;
      cognito_id?: string;
   };

   // This is for legacy Square approved payments
   legacyAmountPaid?: number;
   opts?: GtmOpts;
}

export const gtmTrackCartEvent = (data: GtmTrackCartEvent) => {
   const {
      plans,
      eventName,
      cartTotals,
      paymentOption,
      opts,
      additionalData,
      legacyAmountPaid,
      userData,
   } = data;

   const amountPaid = cartTotals
      ? paymentOption === PaymentOption.Installments
         ? (cartTotals.installments?.amount ?? 0)
         : (cartTotals.onceInFull.amount ?? 0)
      : (legacyAmountPaid ?? plans[0].price);

   TagManager.dataLayer({
      dataLayer: {
         ecommerce: null,
      },
   });
   TagManager.dataLayer({
      dataLayer: {
         event: eventName,
         ecommerce: {
            ...additionalData,
            value: amountPaid,
            tax: 0,
            shipping: 0,
            currency: 'USD',
            items: plans.map((plan) =>
               getGtmEcommerceItem(plan, paymentOption, {
                  metadata: {
                     paymentOption: opts?.metadata?.paymentOption,
                  },
               }),
            ),
         },
         ...(userData ? { userData } : {}),
      },
   });
};

export const useTrackPurchaseButtonClick = () => {
   const { data: session } = useSession();
   const { answers } = useInitialScreeningAnswers();
   const paymentProvider = useCheckoutStore((s) => s.paymentProvider);
   const {
      cart: { paymentMethod },
   } = useCart();

   const fallbackEmail = session?.email ?? answers?.['user-email'] ?? '';

   const track = useCallback(
      ({
         email,
         ...data
      }: Omit<
         CheckoutClickPurchaseButtonEventData,
         'selected_payment_provider' | 'selected_payment_frequency'
      > & { email: string }) => {
         if (!paymentMethod) {
            return;
         }

         const isEmailFilled = !!email;

         webApiClient.customerio
            .trackBeforeSignup({
               body: {
                  email: isEmailFilled ? email : fallbackEmail,
                  name: CustomerIOEvents.CheckoutClickPurchaseButton,
                  app_source: getAppSource(),
                  data: {
                     ...data,
                     selected_payment_provider: paymentProvider,
                     selected_payment_frequency: paymentMethod,
                  },
               },
            })
            .catch(handleSentryException);
      },
      [paymentMethod, paymentProvider, fallbackEmail],
   );

   return {
      track,
   };
};

export const getAppSource = (): 'ios' | 'android' | 'web' => {
   // If the device is not defined, we are on the web
   if (!window.device) {
      return 'web';
   }

   // If the device is defined, we are on the app (ios or android)
   return window.device;
};
