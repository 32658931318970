'use client';

import useMyPatient from '@/hooks/react-query/useMyPatient';
import { Flex, Skeleton, Text } from '@chakra-ui/react';
import { ButtonLink } from '../Cards/components/components/ButtonLink';

export const AdminImpersonatorBanner = () => {
   const { data: patient } = useMyPatient();
   return (
      <Flex
         position="fixed"
         top={0}
         left={0}
         right={0}
         zIndex="modal"
         bg="rgba(255, 0, 0, 0.15)"
         w="full"
         h="full"
         pointerEvents="none"
         boxShadow="0 0 10px 0 rgba(255, 0, 0, 0.5)"
         border="2px solid red"
         rounded="xl"
         overflow="hidden"
      >
         <Skeleton
            as={Flex}
            isLoaded={!!patient}
            position="fixed"
            borderTopRadius="xl"
            top={0}
            left={0}
            right={0}
            bg="red.500"
            p={0.5}
            zIndex="modal"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            gap={2}
            pointerEvents="auto"
            flexDir={{ base: 'column', md: 'row' }}
         >
            <Text color="white" fontSize="xs">
               You are currently impersonating {patient?.firstName}{' '}
               {patient?.lastName} ({patient?.email})
            </Text>
            <ButtonLink
               href="/auth/signout"
               variant="link"
               size="xs"
               color="white"
               fontSize="xxs"
               mt={-1}
            >
               Close session
            </ButtonLink>
         </Skeleton>
      </Flex>
   );
};
