'use client';

import { useEffect } from 'react';

import { webApiClient } from '@/api-client/apiClient';

import useNetworkStatus from './useNetworkStatus';

const sendEvents = async () => {
   // get all events from local storage
   // send them to CIO
   // if successful, remove them from local storage

   for (const [key, value] of Object.entries(localStorage)) {
      const match = /^_cio_event_(\d+)$/.exec(key);
      if (match?.[1]) {
         const createdAt = parseInt(match[1]);
         try {
            const event = JSON.parse(value);

            await webApiClient.customerio.track({
               body: {
                  ...event,
                  timestamp: Math.floor(createdAt / 1000),
               },
            });

            // managed to send the event to our backend
            // remove it from local storage
            localStorage.removeItem(key);
         } catch (e) {
            console.error(e);
         }
      }
   }
};

const useSendErroredCIOEvents = () => {
   // Try to send when we're back online
   useNetworkStatus({
      onOnline: sendEvents,
   });

   // Try to send events initially
   useEffect(() => {
      sendEvents();
   }, []);
};

const CIOEventsSender = () => {
   useSendErroredCIOEvents();
   return null;
};

export default CIOEventsSender;
