'use client';

import { invalidateFailedPayments } from '@/hooks/react-query/useFailedPayments';
import { invalidateMissedAppointments } from '@/hooks/react-query/useMissedAppointments';
import { invalidateMyPatient } from '@/hooks/react-query/useMyPatient';
import { invalidateAppointmentBank } from '@/hooks/react-query/useProgramEncounters';
import { invalidateUpcomingAppointments } from '@/hooks/react-query/useUpcomingAppointments';
import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';
import { handleSentryException } from '@/utils/sentry';
import {
   eventPayloadSchema,
   ServerSideEventType,
   WelkinCdtEnum,
} from '@innerwell/dtos';
import { useQueryClient } from '@tanstack/react-query';
import { type PropsWithChildren } from 'react';
import useWebSocket from 'react-use-websocket';

export const WithWebsocket = ({ children }: PropsWithChildren) => {
   const queryClient = useQueryClient();

   useWebSocket(getClientPublicRuntimeConfig().wsUrl, {
      heartbeat: {
         message: 'ping',
         timeout: 60000, // 1 minute, if no response is received, the connection will be closed
         interval: 30000, // every 30 seconds, a ping message will be sent
      },
      shouldReconnect: () => true,
      reconnectAttempts: 5,
      reconnectInterval: 3000,
      onError: (event) => {
         handleSentryException(event);
      },
      onMessage: (event) => {
         const parsed = eventPayloadSchema.safeParse(event.data);

         if (!parsed.success) {
            return;
         }

         const eventName = parsed.data.event;

         if (
            eventName === ServerSideEventType.Encounter ||
            eventName === ServerSideEventType.EncounterDisposition
         ) {
            invalidateUpcomingAppointments(queryClient);
         }

         if (eventName === ServerSideEventType.Patient) {
            invalidateMyPatient(queryClient);
         }

         if (eventName === ServerSideEventType.Cdt) {
            const { cdtName } = parsed.data.data;

            if (cdtName === WelkinCdtEnum.CdtEncounterBank) {
               invalidateAppointmentBank(queryClient);
            }

            if (cdtName === WelkinCdtEnum.CdtFailedPayment) {
               invalidateFailedPayments(queryClient);
            }

            if (cdtName === WelkinCdtEnum.CdtMissedAppointments) {
               invalidateMissedAppointments(queryClient);
            }

            if (
               [
                  WelkinCdtEnum.CdtInsuranceInfo,
                  WelkinCdtEnum.CdtPatientParams,
                  WelkinCdtEnum.CdtMedicalIntakeStatus,
                  WelkinCdtEnum.CdtPatientDischarged,
               ].includes(cdtName)
            ) {
               invalidateMyPatient(queryClient);
            }
         }
      },
   });

   return children;
};
