import { type QueryClient, useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const invalidateMissedAppointments = (queryClient: QueryClient) => {
   queryClient.invalidateQueries({ queryKey: queryKeys.missedAppointments });
};

export const useMissedAppointments = () => {
   return useQuery({
      queryKey: queryKeys.missedAppointments,
      queryFn: async () => {
         const response =
            await webApiClient.missedAppointments.getMissedAppointments();

         return response.body;
      },
   });
};
