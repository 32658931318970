import { type SignupScreeningAnswers } from '@innerwell/dtos';
import { useEffect, useState } from 'react';

import formsortLocalStorage from '@/utils/formsortLocalStorage';

export const useInitialScreeningAnswers = () => {
   const [initialScreeningAnswers, setInitialScreeningAnswers] =
      useState<SignupScreeningAnswers | null>(null);

   const [isSet, setIsSet] = useState(false);

   useEffect(() => {
      const initialScreeningData =
         formsortLocalStorage.getInitialScreeningAnswers();

      setIsSet(true);
      if (initialScreeningData) {
         setInitialScreeningAnswers(initialScreeningData);
      }
   }, []);

   return {
      answers: initialScreeningAnswers,
      isSet,
   };
};
